.homePage {
  background: url(../images/greenPills.jpg) no-repeat center center fixed ;
  -webkit-background-size: 100%;
  -moz-background-size: cover;
  -o-background-size: cover; 
  background-size: cover; 
  padding-top: 10px;
  padding-bottom: 100%;
  min-width: 300px;
}

.demoFont {
    margin-top: 15px;
    display: block;
    color: black;
    border: 3px solid grey;
    border-radius: 20px;
    width: 40px;
}


p {
    color: rgb(27, 63, 38);
    font-weight: bold;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1.5em;
}

@media only screen and (max-width: 400px) {
    p {
        font-size: 12px;
    }
}


@media only screen and (min-width: 600px) {
    p {
        width: 550px;
    }
}
@media only screen and (min-width: 800px) {
    .sampleImages {
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (min-width: 1000px) {
    .homePage {
        background: lightgoldenrodyellow;
    }
    p {
        padding: 10px;
        margin-right: auto;
        margin-left: auto;
        font-size: 20px;
    }
    .pillImage {
        margin-right: auto;
        margin-left: auto;
        padding: 75px;
        height: 100px;
        width: 100px;
        border-radius: 500px;
        background: url(../images/greenPills2.jpg) ;

    }
}

