
.App {
  font-family: 'Manjari', sans-serif;
  text-align: center;
}


button {
  font-family: 'Manjari', sans-serif;
  cursor: pointer;
  font-size: 15px;
  border: transparent;
  box-shadow: 3px 5px #888888;
  color: rgb(43, 54, 77);
}

button:hover {
  cursor: pointer;
}

footer {
  background: url(../images/01_green_marble.jpg) no-repeat center center fixed;
  -webkit-background-size: 100%;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 100%;
  width: 100%;
}

@media only screen and (max-width: 400px) {
  button {
    font-size: 10px;
    height: 15px;
  }
}


