.loginContainer {
    margin-top: 50px;
    background: url(../images/images.jpg) no-repeat center center fixed;
    -webkit-background-size: 100%;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 100%;
    margin: 0px;
    min-width: 300px;

}
input {
    margin-left: 5px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
}


#loginForm_password {
    margin-left: 5px;
    width: 129px;
    height: 30px;
}
#loginForm_username {
    width: 127px;
    margin-bottom: 5px;
    height: 30px;
}

div.password {
    padding-left: 0px;
}

.userName {
    text-align: left;
    padding-left: 25%;
}

button {
    margin-top: 10px;
    background: rgb(185, 185, 243);
    border-radius: 5px;
    height: 27px;
}

button:hover {
    cursor: pointer;
}

.loginInputs {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}
.loginInputsDemo {
    visibility: hidden;
    height: 0px;
}
.loginButton {
    width: 200px;
}
.demoButton {
    width: 200px;
}
#demoForm {
    display: inline;
}

@media only screen and (max-width: 400px) {
    #loginForm {
        font-size: 13px;
    }
}

