#addMedForm {
    padding-top: 10px;
    padding-left: 15px;
    text-align: left;
    height: 700px;
    background-color: rgb(238, 238, 213);
    padding-bottom: 100%;
}

.addApptButtons {
    width: 100px;
}

.rx {
    padding-left: 20px;
}

.addNote {
    padding-right: 5px;
}

#addMedForm_medDate, #addMedForm_time, #addMedForm_medName, #addMedForm_amountTaken, #addMedForm_reasonForIntake {
    height: 30px;
}
@media only screen and (max-width: 400px) {
    #addMedForm {
        font-size: 12px;
    }
}

@media only screen and (min-width: 450px) {
    #addMedForm {
        width: 100%;
        text-align: center;

    }
}