.Header {
    font-size: 10px;
    text-align: left;
    height: 100%;
    width: 100%;
    background-image: url(../images/01_green_marble.jpg); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-width: 300px;    
}
.caption {
    color: #6A9A7E;
    width: 270px;
    margin-bottom: 2px;
    margin-top: 0px;
    margin-left: 0px;
    font-size: 15px;
    font-style: italic;
}

header {
    width: 300px;
    color: rgb(85, 84, 84);
}
h1 {
    font-weight: bolder;
    color: rgb(43, 54, 77);
}

.title {
    display: inline-block;
    background-image: url(../images/medilogo.jpg);
    height: 60px;
    width: 200px;
    margin-bottom: 0px;
    border-radius: 20px;
}

h2 {
    font-size: 12px;
    margin-top: 0px;
    padding-left: 10px;
    padding-bottom: 10px;
}

a {
    font-weight: bolder;
    font-size: 15px;
    color: #6A9A7E;
    text-decoration: none;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
}

.Header__logged-in, .Header__not-logged-in {
    border: 2px outset #6A9A7E;
    margin-top: 5px;
    background-color: white;
    border-radius: 20px;
    padding: 2px;
    float: right;
    margin-right: 10px;
}

.Header__logged-in:hover {
    cursor: pointer;
}
.Header__not-logged-in:hover {
    cursor: pointer;
}

a:hover {
    color: blue;
    cursor: pointer;
}