.myPastAppts {
    background: lightgoldenrodyellow;
    padding-top: 10px;
    padding-bottom: 100%; 
    min-width: 300px;

}
.pastAppt {
    display: flex;
    flex-direction: row;
}
ul.pastApptCardListing {
    text-align: left;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: flex-start;
    padding-left: 15px;
    width: 100px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.apptLocation {
    text-align: left;
}

.pastApptCard {
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: rgb(155, 167, 133);
    border-radius: 5px;
    width: 280px;
    box-shadow: 3px 5px #888888;
    color: rgb(80, 79, 79);
}

.apptDate {
    font-weight: bolder;
    color: rgb(43, 54, 77);
}

.otherNotes {
    list-style-type: none;
}

label {
    display: block;
}

 section.pastApptBillingCard {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
} 

div.headings {
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
    color: rgb(43, 54, 77);
}

h4 {
    display: flex;
    justify-content: space-between;
}

ul.billing {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 0px;
    background-color: rgb(218, 218, 218);
    padding-top: 10px;
    margin: 0px;
    
}
h4.copay {
    padding-left: 30px;
    padding-right: 30px;
}
h4.docBill {
    padding-left: 20px;
    padding-right: 20px;
}
.billingPrice {
    padding-left: opx;
}
.pastInputDate {
    margin-right: auto;
    margin-left: auto;
    height: 30px;
    width: 150px;
    margin-bottom: 30px;
}

.pastApptCardButtons {
    padding-bottom: 10px;
}

.pastApptDateSearch {
    height: 50px;
}

.searchedAppts {
    width: 280px;
}

@media only screen and (max-width: 400px) {
    .searchedPastAppts {
        font-size: 12px;
    }
    .searchedAppts {
        width: 220px;
    }
    .pastApptCard {
        font-size: 10px;
        width: 230px;
        height: 200px;
    }
    h4.copay {
        padding-right: 10px;
    }
    h4.insuranceBill {
        padding-right: 10px;
    }
}

@media only screen and (min-width: 690px) {
    .apptCardInfo {
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width: 1000px){
    .myPastAppts {
        background: lightgoldenrodyellow;
    }
    .apptCardInfo {
        width: 100%;
    }

}

