.myUpcomingAppts{
    background: lightgoldenrodyellow;
    padding-top: 10px;
    padding-bottom: 100%; 
    min-width: 300px;

}
.apptCardInfo {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.searchedAppts {
    margin-top: 10px;
    background-color: rgb(238, 238, 148);
    box-shadow: 3px 5px #888888;
    width: 250px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    
}
.upcomingApptCard {
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: rgb(172, 202, 128);
    box-shadow: 3px 5px #888888;
    color: rgb(80, 79, 79);
}

.upcomingAppt {
    display: flex;
    flex-direction: row;
    height: 125px;
}
.apptCardListing {
    width: 100px;
    padding-left: 10px;
    text-align: left;
    list-style-type: none;
    display: flex;
    flex-direction: column;
}

.apptCardButtons {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding-top: 0px;
}

.apptCard {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}

.apptCardButtonsList {
    width: 75px;
    margin: 5px;
}

.addApptLink {
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    background-color: rgb(185, 185, 243);
    box-shadow: 3px 5px #888888;
    color: rgb(80, 79, 79);
    display: block;
    width: 180px;
    margin-right: auto;
    margin-left: auto;
    
}

.addApptLink:hover {
    cursor: pointer; 
}

.apptCardButtonsListNotes {
    width: 75px;
}

.apptDate, .apptTime, .apptDoctor, .apptLocation, .apptPurpose {
    width: 150px;
    margin-bottom: 5px;
}

.viewAllButton {
    margin-top: 0px;
    margin-bottom: 20px;
}

.otherNotes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.upApptEdit {
    width: 100px;
    margin-bottom: 5px;
}

.upApptEdit:hover {
    cursor: pointer;
}

.inputDate {
    margin-right: auto;
    margin-left: auto;
    height: 30px;
    width: 150px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 400px) {

    .upcomingApptCard {
        width: 220px;
        height: 130px;
    }
    .apptCard {
        width: 220px;
    }
    .upcomingAppt {
        height: 100px;
    }
    .apptCardListing, .otherNotes {
        height: 80px;
        font-size: 10px;
        margin-bottom: 0px;
    }
    .upApptEdit {
        margin-top: 0px;
        width: 40px;
    }
    .apptCardButtons, a {
        height: 20px;
    }
    .addApptLink {
        font-size: 13px;
        height: 12px;
    }
    .searchedAppts {
        font-size: 12px;
    }

}

@media only screen and (min-width: 700px) {
        section.pastApptCard {
            width: 300px;
        }
      div.searchedAppts {
          width: 300px;
      }
      .myUpcomingApptsHeader {
          width: 500px;
          margin-right: auto;
          margin-left: auto;
      }
      .myUpcomingAppts {
          width: 100%;
      }
      .viewAllButton {
          margin-right: auto;
          margin-left: auto;
      }
      .upcomingApptCard {
          width: 300px;
          margin-right: auto;
          margin-left: auto;
      }  
       .apptCardInfo {
          width: 300px;
      }
  }

  @media only screen and (min-width: 800px) {
      .myUpcomingAppts {
          width: 100%;
          margin-right: auto;
          margin-left: auto;
      }
      .myUpcomingApptsHeader {
        margin-right: auto;
        margin-left: auto;
      }
      .upcomingApptCard {
        margin-right: auto;
        margin-left: auto;
      }
  }

  @media only screen and (min-width: 1000px) {
      section.pastApptCard {
          width: 400px;
      }
      div.searchedAppts{
          width: 400px;
      }
    .myUpcomingAppts {
        background: lightgoldenrodyellow;
    }
      .apptCardInfo {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
      }
      .myUpcomingApptsHeader {
        margin-right: auto;
        margin-left: auto;
      }
      .viewAllButton {
        margin-right: auto;
        margin-left: auto;
      }
      .apptCardInfo {
        width: 1000px;
      }
      .apptCard {
        width: 1000px;
        margin-right: auto;
        margin-left: auto;
      }
      .upcomingApptCard {
        width: 400px;
      }  
  }

 