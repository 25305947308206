#registrationForm {
    margin-top: 50px;
    background: url(../images/images.jpg) no-repeat center center fixed;
    -webkit-background-size: 100%;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 100%;
    margin: 0px;

}

.registerInputs {
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}
input {
    margin-left: 5px;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 5px;
}

#registrationForm_userName {
    height: 30px;
}

#registrationForm_email {
    width: 160px;
    height: 30px;
}

#registrationForm_password {
    width: 129px;
    height: 30px;
}

.userName, .email, .registrationPassword {
    text-align: left;
    padding-left: 25%;
}

button {
    margin-top: 10px;
    background: rgb(185, 185, 243);
    border-radius: 5px;
    height: 27px;
}

button:hover {
    cursor: pointer;
}

.red {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    color: red;
}

.passwordReq {
    margin-right: auto;
    margin-left: auto;
    width: 200px;
    font-size: 12px;
    text-align: left;
}

@media only screen and (max-width: 400px) {
    #registrationForm {
        font-size: 10px;
    }
}