#addApptForm {
    padding-left: 15px;
    text-align: left;
    background-color: rgb(238, 238, 213);
    padding-top: 12px;
    padding-bottom: 100%;
}

#addApptForm_apptDate {
    width: 100px;
    height: 30px;
}
#addApptForm_apptTime {
    width: 98px;
    height: 30px;
}

#addApptForm_apptLocation {
    width: 188px;
    height: 30px;
}

#addApptForm_apptDoctor {
    width: 200px;
    height: 30px;
}

#addApptForm_purposeForVisit, #addApptForm_apptNotes {
    height: 50px;
}

.addApptButtons {
    margin-right: 25px;
}

@media only screen and (max-width: 400px) {
    .addAppt {
        font-size: 12px;
    }

}

@media only screen and (min-width: 400px) {
    .purposeForVisit, .apptNotes {
        text-align: center;
    }
    .apptDate, .apptTime, .apptLocation, .apptDoctor {
        width: 300px;
        margin-right: auto;
        margin-left: auto;
    }
   #addApptForm_purposeForVisit {
        display: block;
    }
    input#addApptForm_purposeForVisit {
        margin-right: auto;
        margin-left: auto;

    }
    .submitApptButtons {
        width: 375px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
    .addApptButtons {
        margin-right: auto;
        margin-left: auto;     
    }
}