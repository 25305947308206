nav {
    text-align: left;
    width: 100%;  
}

.medLog {
    text-align: center;
    background: rgb(238, 238, 148);
    padding: 5px;
    font-weight: bolder;
    display: block;
    color: rgb(36, 67, 116); 
}

.upcomingAppts {
    text-align: center;
    background: rgb(172, 202, 128);
    padding: 5px;
    font-weight: bolder;
    display: block;
    color: rgb(36, 67, 116); 
}

.pastAppts {
    text-align: center;
    background: rgb(155, 167, 133);
    padding: 5px;
    font-weight: bolder;
    display: block;
    color:rgb(36, 67, 116);  
}

.medLog:hover {
    cursor: pointer;
    color: blue;
}

.pastAppts:hover {
    cursor: pointer;
    color: blue;
}
.upcomingAppts:hover {
    cursor: pointer;
    color: blue;
}


@media only screen and (min-width: 600px)
  {
      nav {
          display: flex;
          flex-direction: row;
      }
       
      .upcomingAppts, .medLog, .pastAppts {
          width: 100%;
          padding: 20px;
      }
  }